














































































import { Component, Watch } from "vue-property-decorator";
import { Roles, deleteRole } from "@/graphql/queries/Roles.graphql";
import { Role } from "@/generated/graphql";
import { Headers } from "@/types/table";
import { Routes } from "@/types/core";
import userRolesMixin from "@/mixins/userRoles";
import { BaseView } from "@/core/UI/Views/BaseView";
import { mixins } from "vue-class-component";
import UsersList from "@/components/widgets/user/UsersList.vue";

@Component({
  components: { UsersList },
  apollo: {
    roles: {
      query: Roles,
      fetchPolicy: "no-cache",
    },
  },
})
export default class RolesView extends mixins(userRolesMixin, BaseView) {
  headers: Headers = [
    { text: "Роль", value: "name", sortable: false },
    { text: "Пользователи", value: "users", sortable: false },
    { text: "Удалить", value: "actions", sortable: false },
  ];
  items: Role[] = [];
  filteredItems: Role[] = [];
  loading: boolean = false;
  first: number = 5;
  page: number = 1;
  totalItems: number = 20;
  roles: Role[] = [];
  saved: boolean = false;
  error: boolean = false;
  errorText: string = "";
  protected Routes = Routes;

  created() {
    if (!this.VIEW_ROLES_LIST) this.$router.push({ name: Routes.noRights });
  }

  @Watch("roles")
  onRoles(): void {
    this.items = this.roles?.map((role: Role) => {
      return {
        id: role.id,
        name: role.name,
        users: role.users,
        permissions: [...role.permissions],
      };
    });
    this.filteredItems = [...this.items];
  }

  onFilterChange(key: keyof Role, text: string): void {
    const filteredItems: Role[] = this.items.filter(
      (employee: Role) =>
        ((key ? employee[key] : "") || "")
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) > -1
    );
    this.filteredItems = filteredItems;
  }
  clickRow(row: Role): void {
    if (row.id === "1") return;
    this.$router.push({
      name: Routes["admin/roles/role"],
      params: { id: row.id },
    });
  }

  async deleteRole(id: string): Promise<void> {
    try {
      const data = await this.$apollo.mutate({
        mutation: deleteRole,
        variables: {
          id,
        },
      });
      this.saved = true;
      this.items = this.items.filter((item: Role) => item.id !== id);
      this.filteredItems = this.filteredItems.filter(
        (item: Role) => item.id !== id
      );
    } catch (err: any) {
      console.log(err.graphQLErrors);
      this.errorText = err.graphQLErrors[0].debugMessage;
      this.error = true;
    }
  }
}
