var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UsersTable',_vm._b({attrs:{"items":_vm.list.data,"show-select":_vm.showSelect},on:{"pagination":_vm.paginate},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"filters",fn:function(){return [_c('v-row',{staticClass:"mb-4",attrs:{"justify":"end"}},[_c('v-text-field',{staticStyle:{"max-width":"500px"},attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск","hide-details":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-row',[_c('v-col',[_c('RolesAutocomplete',{attrs:{"return-object":"","multiple":"","deletable-chips":"","chips":"","clearable":""},on:{"change":function($event){return _vm.refetch()}},model:{value:(_vm.filters.roles),callback:function ($$v) {_vm.$set(_vm.filters, "roles", $$v)},expression:"filters.roles"}})],1),_c('v-col',[_c('SpecialtiesAutocomplete',{attrs:{"multiple":"","deletable-chips":"","chips":"","clearable":""},on:{"change":function($event){return _vm.refetch()}},model:{value:(_vm.filters.specialties),callback:function ($$v) {_vm.$set(_vm.filters, "specialties", $$v)},expression:"filters.specialties"}})],1),_c('v-col',[_c('ServerCityAutocomplete',{attrs:{"multiple":"","deletable-chips":"","chips":"","clearable":""},on:{"change":function($event){return _vm.refetch()}},model:{value:(_vm.filters.cities),callback:function ($$v) {_vm.$set(_vm.filters, "cities", $$v)},expression:"filters.cities"}})],1)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('div',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.filters = Object.assign({}, _vm.initialFilters);
                _vm.additionalFilters = Object.assign({}, _vm.initialAdditionalFilters);
                _vm.refetch();}}},[_vm._v(" сбросить ")]),_c('v-dialog',{attrs:{"max-width":"500","scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","color":"primary"}},on),[_vm._v(" Еще фильтры ")])]}}]),model:{value:(_vm.additionalFiltersVisible),callback:function ($$v) {_vm.additionalFiltersVisible=$$v},expression:"additionalFiltersVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v("Дополнительные фильтры")]),_c('v-divider'),_c('v-card-text',[_c('PositionsAutocomplete',{attrs:{"multiple":"","deletable-chips":"","chips":"","clearable":""},model:{value:(_vm.additionalFilters.positions),callback:function ($$v) {_vm.$set(_vm.additionalFilters, "positions", $$v)},expression:"additionalFilters.positions"}}),_c('WorkplacesAutocomplete',{attrs:{"multiple":"","deletable-chips":"","chips":"","clearable":""},model:{value:(_vm.additionalFilters.workplaces),callback:function ($$v) {_vm.$set(_vm.additionalFilters, "workplaces", $$v)},expression:"additionalFilters.workplaces"}}),_c('v-select',{attrs:{"label":"Тип больницы","items":_vm.hospitalTypes,"deletable-chips":"","chips":"","clearable":""},model:{value:(_vm.additionalFilters.hospitalType),callback:function ($$v) {_vm.$set(_vm.additionalFilters, "hospitalType", $$v)},expression:"additionalFilters.hospitalType"}}),_c('AssociationsAutocomplete',{attrs:{"multiple":"","deletable-chips":"","chips":"","clearable":""},model:{value:(_vm.additionalFilters.associations),callback:function ($$v) {_vm.$set(_vm.additionalFilters, "associations", $$v)},expression:"additionalFilters.associations"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.additionalFiltersVisible = false;
                      _vm.refetch();}}},[_vm._v(" применить ")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.additionalFilters = Object.assign({}, _vm.initialAdditionalFilters);
                      _vm.additionalFiltersVisible = false;
                      _vm.refetch();}}},[_vm._v(" сбросить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.additionalFiltersVisible = false}}},[_vm._v("отменить")])],1)],1)],1)],1)])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'UsersTable',_vm.tableBindings,false))}
var staticRenderFns = []

export { render, staticRenderFns }