








































































































































































import { Component, Prop, VModel, Watch } from "vue-property-decorator";
import Table from "@components/parts/tables/Table.vue";
import {
  City,
  DoctorAssociation,
  DoctorPosition,
  DoctorSpecialty,
  DoctorWorkplace,
  EmployeeSpecialty,
  HospitalTypeEnum,
  InputUsersEnum,
  SqlOperator,
  Role,
  User,
  UsersListQueryVariables,
  WorkplacesFilterInput,
  JsonFilterInput,
  UsersShortDataListQueryVariables,
  QueryUsersOrderByColumn,
  SortOrder,
} from "@/generated/graphql";
import { DocumentNode } from "graphql";
import { UsersShortDataList as UsersListQuery } from "@/graphql/queries/User.graphql";

import ListView from "@/core/UI/Views/ListView";
import UsersTable from "@/components/parts/tables/UsersTable.vue";
import RolesAutocomplete from "@/components/widgets/commons/Inputs/RolesAutocomplete.vue";
import SpecialtiesAutocomplete from "@/components/widgets/commons/Inputs/SpecialtiesAutocomplete.vue";
import WorkplacesAutocomplete from "@/components/widgets/commons/Inputs/WorkplacesAutocomplete.vue";
import PositionsAutocomplete from "@/components/widgets/commons/Inputs/PositionsAutocomplete.vue";
import AssociationsAutocomplete from "@/components/widgets/commons/Inputs/AssociationsAutocomplete.vue";
import ServerCityAutocomplete from "@/components/widgets/commons/Inputs/ServerCityAutocomplete.vue";

import { Debounce } from "vue-debounce-decorator";
import { hospitalTypes } from "@/core/static/dict";
import { merge } from "lodash";

const initialFilters: {
  search: string;
  roles: Role[];
  specialties: DoctorSpecialty[] | EmployeeSpecialty[];
  cities: City[];
} = {
  search: "",
  roles: [],
  specialties: [],
  cities: [],
};

const initialAdditionalFilters: {
  positions: DoctorPosition[];
  workplaces: DoctorWorkplace[];
  hospitalType: HospitalTypeEnum | null;
  associations: DoctorAssociation[];
} = {
  hospitalType: null,
  positions: [],
  workplaces: [],
  associations: [],
};

@Component({
  name: "UsersList",
  components: {
    Table,

    UsersTable,
    RolesAutocomplete,
    SpecialtiesAutocomplete,
    WorkplacesAutocomplete,
    PositionsAutocomplete,
    AssociationsAutocomplete,
    ServerCityAutocomplete,
  },
})
export default class UsersList extends ListView<User> {
  @Prop({ type: Boolean, default: false }) showSelect!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  extraParams!: UsersListQueryVariables;

  @VModel({ type: Array, default: () => [] }) selected!: unknown[];

  protected readonly fetchListQuery: DocumentNode = UsersListQuery;
  protected readonly fetchKey: string = "users";

  protected additionalFiltersVisible = false;
  protected readonly initialFilters = initialFilters;
  protected filters = { ...initialFilters };

  protected readonly initialAdditionalFilters = initialAdditionalFilters;
  protected additionalFilters = { ...initialAdditionalFilters };

  protected readonly hospitalTypes = hospitalTypes;

  protected get fetchListVariables(): UsersShortDataListQueryVariables {
    const isSearchByPhone = /^\d+$/g.test(this.filters.search);
    const isSearchByEmail = /^.+@.+\..+$/g.test(this.filters.search);

    const filtersSearch: {
      where: UsersShortDataListQueryVariables["where"];
      phones: JsonFilterInput;
      emails: JsonFilterInput;
      workplaces: WorkplacesFilterInput;
      orderBy: UsersShortDataListQueryVariables["orderBy"];
    } = {
      where: {
        AND: [],
      },
      phones: {
        value: "",
      },
      emails: {
        value: "",
      },
      workplaces: {},
      orderBy: [{ column: QueryUsersOrderByColumn.Id, order: SortOrder.Desc }],
    };

    if (this.filters.roles.length) {
      filtersSearch.where?.AND?.push({
        HAS: {
          relation: "roles",
          condition: {
            column: InputUsersEnum.Id,
            operator: SqlOperator.In,
            value: this.filters.roles.map((r) => r.id),
          },
        },
      });
    }

    if (this.filters.cities.length) {
      filtersSearch.where?.AND?.push({
        HAS: {
          relation: "city",
          condition: {
            column: InputUsersEnum.Id,
            operator: SqlOperator.In,
            value: this.filters.cities.map((c) => c.id),
          },
        },
      });
    }

    if (this.filters.search.length) {
      if (isSearchByPhone) {
        filtersSearch.phones.value = this.filters.search.trim();
      } else if (isSearchByEmail) {
        filtersSearch.emails.value = this.filters.search.trim();
      } else {
        const names = this.filters.search.trim().split(" ");
        filtersSearch.where?.AND?.push({
          AND: names.map((name) => ({
            OR: [
              {
                column: InputUsersEnum.FirstName,
                operator: SqlOperator.Ilike,
                value: `%${name}%`,
              },
              {
                column: InputUsersEnum.FamilyName,
                operator: SqlOperator.Ilike,
                value: `%${name}%`,
              },
              {
                column: InputUsersEnum.SecondName,
                operator: SqlOperator.Ilike,
                value: `%${name}%`,
              },
            ],
          })),
        });
      }
    }

    if (this.filters.specialties.length) {
      filtersSearch.where?.AND?.push({
        HAS: {
          relation: "doctorProfile",
          condition: {
            HAS: {
              relation: "specialties",
              condition: {
                column: InputUsersEnum.Id,
                operator: SqlOperator.In,
                value: this.filters.specialties,
              },
            },
          },
        },
      });
    }

    if (this.additionalFilters.workplaces.length) {
      filtersSearch.where?.AND?.push({
        HAS: {
          relation: "doctorProfile",
          condition: {
            HAS: {
              relation: "workplaces",
              condition: {
                column: InputUsersEnum.Id,
                operator: SqlOperator.In,
                value: this.additionalFilters.workplaces,
              },
            },
          },
        },
      });
    }

    if (this.additionalFilters.positions.length) {
      filtersSearch.where?.AND?.push({
        HAS: {
          relation: "doctorProfile",
          condition: {
            HAS: {
              relation: "positions",
              condition: {
                column: InputUsersEnum.Id,
                operator: SqlOperator.In,
                value: this.additionalFilters.positions,
              },
            },
          },
        },
      });
    }

    if (this.additionalFilters.associations.length) {
      filtersSearch.where?.AND?.push({
        HAS: {
          relation: "doctorProfile",
          condition: {
            HAS: {
              relation: "associations",
              condition: {
                column: InputUsersEnum.Id,
                operator: SqlOperator.In,
                value: this.additionalFilters.associations,
              },
            },
          },
        },
      });
    }

    filtersSearch.workplaces.type = this.additionalFilters.hospitalType;

    return merge(
      {
        ...filtersSearch,
      },
      this.extraParams
    );
  }

  @Watch("filters.search", { deep: true })
  @Debounce(500)
  private search(): void {
    this.refetch();
  }
}
